import Layout from "../components/layout";

const FourOhFour = () => {
  return (
    <Layout description="test" pageTitle={404}>
      <h2>Page Not Found</h2>
    </Layout>
  )
}

export default FourOhFour;

